html {
	width: 100%;
	background-color: #fff;
}

body {
	width: 100%;
	color: #757775;
}

span {
	display: inline-block;
}

a {
	color: inherit;
	text-decoration: none;
	cursor: pointer;
	transition:all 0.2s;

	&:--pocus {
		text-decoration: underline;
	}
}

[href*='http://bluefishds.com'] {

	&:--pocus {
		color: #0099DC;
		text-decoration: none;
	}
}

/* overrides what is in base/_base.css */
a[href]:empty::before {
  display: none;
}

.clear:after{
  content:"";
  display:table;
  clear:both;
}

.page-wrap {
	margin-left: auto;
	margin-right: auto;
	max-width: 1300px;
}

@media only screen and (max-width: 768px) {
	.page-wrap {
		margin-left: 0;
		margin-right: 0;
	}
}
