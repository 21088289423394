/* 
 * main content wrapper 
 * single column
*/
/*
.main{
	min-width:1180px;
	max-width:1180px;
	background-color: #fff;
}
*/

a {
	word-break: break-all;
}

.content{
	padding-top: 30px;
	padding-bottom: 30px;
	background-color: #fff;
}

.content--home{
	padding-top: 0;
	padding-bottom: 0;
}

@media only screen and (min-width: 768px) {
	.content{
		padding-top: 50px;
		padding-bottom: 75px;
	}

	.content--home{
		padding-top: 0;
		padding-bottom: 0;
	}
}

/* remove after template review */
.main-content {
	text-align: center;
}

.page-container{
	background-color: #fff;
}

.page-content{
	display: flex;
	flex-direction: row-reverse;
}

.page-article{
	width: 680px;
}

/* --- article content --- */
.content__page{
	width: 100%;
	max-width: 800px;
	margin: 0 auto;

	& h2{
		font-size: 20px;
		color:var(--color-accent-dark);
	}

	& h3{
		font-size: 1rem;
		color: var(--color-accent-dark);
	}
}

.content__page--full{
	max-width: 1180px;

	& .heading {

		& h2{
			margin-bottom: 15px;
			font-size: 27px;
			font-weight: 700;
		}

		& p{
			font-size: 14px;
			font-weight: normal;
		}
	}
}

/* --- END article content --- */


/* --- sidebar --- */

.container--flex{
	flex-direction:column-reverse;
}

.content__sidebar{
	width: 100%;

}

.sidebar-nav{
	font-size: 14px;
	border-top:1px solid var(--ltgray);

	& .top-level{
		padding-top: 10px;
		padding-bottom: 10px;
		font-size: 14px;
		color: var(--color-accent-dark);
		text-transform: uppercase;
		border-bottom: 1px solid var(--ltgray);
	}
}

.sidebar-nav--calendar{
	& h5{
		padding:8px 0;
		font-size:14px;
		font-weight:900;
	}
}

.parent-nav{

	& li{
		width: 100%;
		display: flex;
		flex-wrap:wrap;
		justify-content:space-between;
		border-bottom: 1px solid var(--ltgray);	
	}


	& a{
		display: block;
		padding-top: 10px;
		padding-bottom: 10px;
		padding-right: 20px;
		color:var(--color-text);
		font-weight: 600;
		line-height: 18px;

		&:--pocus{
			color:var(--color-primary);
			text-decoration: none;
		}
	}

	& a.is-active{
		font-weight: 700;
		color: #f18b21;
		&:--pocus{
			color:var(--color-accent);
		}
	}

	& button{
		padding:8px 0;
		color:#706765;
		background:transparent;
	}

	& .child-nav {
		display: block;
		width: 100%;
		border-top:1px solid var(--ltgray);
		display: none;

		& li{
			padding-left: 15px;
		}

		& li:last-of-type{
			border-bottom:0;
		}
	}
	
}


.child-trigger {
	display: block;
	justify-content:center;
	width: 48px;
	padding-top: 0;
	padding-bottom: 0;
	padding-right: 0 !important;
	line-height: 18px;
	text-align: center;
	cursor: pointer;
	
}

.child-trigger-rotate {
	transform: rotateZ(90deg);
}

/* --- END sidebar --- */



@media only screen and (min-width: 980px) {
	.container--flex{
		flex-direction:row;
	}

	.content__sidebar{
		width: 230px;
		margin-right: 70px;
	}
}

.ads-list {
	list-style: none;
	padding-left: 0;
}

.ad {
	margin-top: 15px;
	margin-bottom: 15px;
}

.video-title {
	margin-bottom: -40px;
}

.content-sponsor-wrap {
	display: flex;
	flex-wrap: wrap;
	margin-top: 30px;
	margin-bottom: 30px;
	& .content-sponsor {
		flex-grow: 1;
		max-width: 150px;
		margin: 20px;
		& img {
			height: 100%;
			width: 100%;
		}
	}
}

@media only screen and (max-width: 768px) {
	.content-sponsor-wrap {
		justify-content: center;
	}
}

.sponsor-title {
	margin-bottom: -35px;
	margin-top: 30px;
}

.file-block {
	margin-top: 30px;
	margin-bottom: 30px;
}

.footnote {
	font-size: 12px;
	font-weight: bold;
	color: #757775;
	text-transform: uppercase;
}

.embed-entry {
	max-width: 100%;
	& iframe {
		max-width: 100%;
	}
}

.trigger-rotate {
transform: rotateZ(45deg);
}

/* -- block overrides -- */
.counterBlock__content h2,
.textImgBlock__content h2{
	margin-bottom: 20px;
	font-size: 27px;
	font-weight: 900;
	color: #546122;
	line-height: 1.2;
}

.counterBlock__content h3,
.textImgBlock__content h3{
	font-size: 24px;
	color: #546122;
}

.counterBlock__content h4,
.textImgBlock__content h4 {
	font-size: 1rem;
	color: #546122;
}

.link-w-arrow{
	position: relative;
	display: block;
	font-weight: 700;

	&::after {
		content: '›';
		position: relative;
		display: inline-flex;
		margin-left: 4px;
	}

	&:--pocus {
		color: #e98322;
		text-decoration: none;
	}
}