nav ul, nav ol,
.no-bull, .inline-list {
  list-style:none;
  margin:0;
  padding:0;
}

nav li,
.no-bull li, .inline-list li {
  margin:0;
}

/*Inline-list helpers. Add custom characters with a modifier and content*/

.inline-list {
	display: flex;
  align-items: center;
}

/*Social media icon list helper*/

.inline-list--social {

  & li {
    margin-right: 15px;

    &:last-of-type {
      margin-right: 0;
    }
  }
}
