.counterBlock{
  position:relative;
  padding:40px 0;
  background:#FDF7E9;
}

@media only screen and (min-width: 768px) { 
  .counterBlock::after{
    content:'';
    position:absolute;
    bottom:0;
    right:0;
    width:110px;
    height:100%;
    max-height:242px;
    background:url('/assets/images/icons/wheat.svg') no-repeat;
  }
}


@media only screen and (min-width: 768px) { 
  .counterBlock__wrap{
    display:flex;
    max-width: 1020px;
    margin:0 auto;
  }
}

.counterBlock__content{

  & p{
    margin-bottom:20px;
    font-size:1rem;
    font-weight:400;
    color: #706765;
    line-height:1.5;
  }

  & a{
    display: inline-block;
    width: fit-content;
    font-weight:900;
    color:#F18B21;
  }
}


@media only screen and (min-width: 768px) { 
  .counterBlock__content{
    order:1;
    flex:none;
    width:40%;
    padding:0 20px;
  }
}


@media only screen and (min-width: 768px) { 
  .counterBlock__counters{
    order:2;
    flex:none;
    width:60%;
    padding:0 20px;
  }
}

.counterBlock__counters-wrap{
  display:flex;
  flex-wrap:wrap;
  margin-right:-10px;
  margin-left:-10px;
}

.counter{
  flex:none;
  width:50%;
  margin-bottom:40px;
  padding:0 10px;
  text-align:center;
}

.counter__content{
  border-radius: 50px 0;
  border: 1px solid #87B2D8;
  margin:0 10px;
  overflow: hidden;
}

.content__page .counter__content h3{
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
  padding: 12px 12px 0;
  /* font-size:54px; */
  font-size: 48px;
  font-weight:900;
  color:#7A426B;
  line-height:1;
}

.content__page .counter__content p{
  margin-bottom:20px;
  color:#706765;
}
