label + input {
  margin-top: 5px;
}

label{
  display:block;
  padding:0;
  margin:0;
  font-size: 0.875rem;
}

input, textarea, select{
  display:block;
  padding: 12px;
  margin:0;
  width: 100%;
  font-size: 0.875rem;
  font-family: var(--font-primary);
  border: none;
  border-radius: 0;
  color: inherit;
  background-color: #fff;
  border:1px solid var(--ltgray);
}

select {
  height:43px;
  margin-top: 5px;
}

/*
input, select {
  height: 32px;
}
*/

input, textarea {

  &:focus{
    border-color:var(--color-primary);
  }

  &::placeholder {
    color: inherit;
    opacity: 1;
    transition: opacity 0.2s;
  }

  &:focus::placeholder {
    opacity: 0.5;
  }
}

input[type=checkbox], input[type=radio]{
  display:inline-block;
  width:auto;
}

textarea {
  resize: none;
}
