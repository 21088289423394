footer {
	background-color: #392E2B;
	width: 100%;
	height: 100%;
	padding-top: 60px;
	padding-bottom: ;
	margin-bottom: 0;
	color: white;

	& .footer-wrap {
		
		width: 100%;
	}
}

.footer-columns {
	display: flex;
	justify-content: space-between;
	text-align: center;
	padding-bottom: 10px;


	& article {
		border-right: solid grey 1px;
		width: 100%;
	}

	& article:nth-last-of-type(1) {
		border-right: solid transparent 2px;
	}
	& h4 {
		color: #F18B21;
		margin-bottom: 10px;
		font-weight: bold;
		text-transform: uppercase;
	}
	& p {
		margin-bottom: 10px;
		color: rgba(255,255,255,0.8);
		font-weight: 600;
		line-height: 1.8;

		& a {
			color: rgba(255,255,255,0.8);
			text-decoration: none;

			&:--pocus{
				color:var(--color-primary);
			}
		}
	}
}

.copywrite {
	text-align: center;
	padding-top: 40px;
	padding-bottom: 30px;

	& p{
		font-weight: 600;
	}
	
	& a {
		color: #8CA33C;
		font-weight: 600;
	}
}

.sponsors {
	background-color: white;
	color: black;
	text-align: center;
	padding-top: 40px;
	padding-bottom: 30px;

	& a {
		display: inline-block;
	}

	& img {
		max-width: 145px;
		height: 65px;
		margin-left: 10px;
		margin-right: 10px;
		display: inline-block;
	}

	& p {
		margin-top: 13px;
		font-size: 11px;
		font-weight: 600;
		color:#706765;


		& strong{
			font-weight: 700;
		}
	}
}



@media only screen and (max-width: 768px) {
	footer {
		padding-top: 5px;

		& .footer-columns {
			flex-direction: column;
			justify-content: space-between;
			text-align: center;
			padding-left: 20px;
			padding-right: 20px;
			& article {
				border-bottom: solid grey 1px;
				border-right: solid transparent;
				padding-top: 35px;
				padding-bottom: 30px;
			}
		}

		& .copywrite {
			padding-bottom: 10px;
			padding-left: 20px;
			padding-right: 20px;
			padding-top: 20px;
		}

		& .sponsors {
			padding-left: 20px;
			padding-right: 20px;
		}

		& .sponsors p {
			margin-top: 0     ;
		}

		& .sponsor-wrap {
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		& .sponsor-wrap img {
			width: 200px;
			margin-bottom: 20px;
		}
	}
}

@media only screen and (min-width: 768px) {
	.footer-columns{

		& p{
			font-size: 1rem;
		}
	}

	.copywrite{

		& p{
			font-size: 1rem;
		}
	}

}