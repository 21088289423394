/* Wrapper element for images and svg icons */

.icon {
  

  & img, & svg {
    height: 18px;

    & * {
      transition: inherit;
    }
  }
}

/* Add modifiers such as .icon--bubble */
