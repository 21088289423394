.content__events .header{
  position:relative;
  padding:23px 0;
  font-size:20px;
  font-weight:700;
  color:#fff;
  background-color: rgb(140,163,60);
  text-transform:uppercase;
}

.content__events .header:after{
  content:'';
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background-image: url(../images/trash1.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.events__list--none{
  padding:27px 0;

  & li{
    padding:0;
    border:none;
  }
}

.events__event-day{
  display:flex;
  padding-top:40px;
  border-bottom:1px solid #E1E1E1;
}

li.events__event-day:nth-last-child(2){
  border-bottom:none;
}

.events__event-day .date{
  margin-right:40px;
  padding:0 25px;
}

.events__event-day .date p{
  font-size:57px;
  font-weight:700;
  color:#8CA33C;
  text-transform:uppercase;
}

.events__event-day .date p:first-of-type{
  margin-bottom:0;
  font-size:14px;
  color:#706765;
  text-align:center;
}

.events__event-day .date__time{
  display:flex;
}

.events__event-day .date__time span{
  display:block;
  font-weight:700;
  color:#706765;
}

.events__event-day .date__time .icon{
  width:18px;
  height:18px;
  margin-right:10px;
}

.events__search{
  display:flex;
  width:60%;
}

.events__input-container{
  display:flex;
  align-items:center;
  width:100%;

  & .icon{
    width:18px;
    height:18px;
    margin-left:12px;
  }
}

.events__search input{
  padding:0 12px 0 5px;
  border:none;
}

.events__search button{
  display: inline-block;
  flex: 0 0 auto;
  font-size:16px;
  font-weight:700;
  line-height:1;
  letter-spacing:0.8px;
  background:#7A426B;
}

.events__calendar{
  display:flex;
}

.events__calendar li a{
  margin: 0 12px;
  padding:12px 0 22px;
  color:#F18B21;
  border-bottom:2px solid transparent;
  border-radius:0;

  &:--pocus{
    border-bottom:2px solid var(--color-primary);
  }
}

.events__calendar li a.event-active{
  border-bottom:2px solid var(--color-primary);
}

.events__calendar li.activate{
  border-right:none;
}

.events__calendar-nav{
  padding-top:60px;
}

.events__calendar-wrap{
  display:flex;
  justify-content:space-between;
  align-items:center;
  margin-bottom:40px;
  border: 1px solid #e1e1e1;
}

.date__content a{
  display:flex;
  align-items:center;
  padding-left:28px;
  margin-bottom:20px;
  color:#8CA33C;
}

.date__content a .icon{
  width:18px;
  height:18px;
  margin-right:10px;
}

.day__title{
  padding-left:0;
  font-size:16px;
  font-weight:700;
  color:#546122;
  line-height:1;
  text-transform:uppercase;
}

.date__summary{
  padding-left:28px;
}

.date__summary p{
  margin-bottom:24px;
}

a.events__btn{
  margin-bottom:40px;
}

.events__sub-nav-wrap{
  display:flex;
  align-items:center;
  margin-bottom:40px;

  & .btn:not(.btn--round){
    padding:5px 14px;
    font-size:10px;
    color:#706765;
    border:1px solid #E1E1E1;
    text-transform:uppercase;
  }

  & p{
    margin:0 0 0 20px;
    font-weight:20px;
    font-weight:700;
    color:#7A426B;
  }
}

.events__sub-nav-scroller-wrap{
  max-width:200px;
  padding:12px;

  & a{
    padding:0;
    color:#706765;

    & .icon{
      width:6px;
      height:11px;
    }
  }
}