blockquote{
	position: relative;
	margin: 24px 0 20px;
	padding: 20px;
	border-width: 2px 0;
	border-style: solid;
	border-color: #eee;
	clear:left;

	& p{
		font-size: 1rem;

		&:last-of-type{
			margin-bottom: 0;
		}
	}

	& cite{
		display: block;
		text-align: right;
	}

	& p + cite{
		padding-top: 20px;
	}
}

blockquote .icon{
	position: absolute;
	top:0; left: 50%;
	display: flex;
	align-items:center;
	justify-content:center;
	text-align: center;
	width: 36px;
	height: 36px;
	color:var(--color-accent-dark);
	background-color: #fff;
	transform: translate(-50%, -50%);

	& svg{
		width: 24px;
		height: 24px;
	}
}

/* this is here for future updates
blockquote::before {
  content: '\201C';
  position: absolute;
  top: 0em;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  width: 3rem;
  height: 2rem;
  font-size: 6em;
  line-height: 1.08em;
  color: var(--color-accent-secondary);
  text-align: center;
}
blockquote::after {
  content: "\2013 \2003" attr(cite);
  display: block;
  text-align: right;
  font-size: 0.875em;
  color: #e74c3c;
}

<blockquote cite="">

background: url("data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M13 14.725c0-5.141 3.892-10.519 10-11.725l.984 2.126c-2.215.835-4.163 3.742-4.38 5.746 2.491.392 4.396 2.547 4.396 5.149 0 3.182-2.584 4.979-5.199 4.979-3.015 0-5.801-2.305-5.801-6.275zm-13 0c0-5.141 3.892-10.519 10-11.725l.984 2.126c-2.215.835-4.163 3.742-4.38 5.746 2.491.392 4.396 2.547 4.396 5.149 0 3.182-2.584 4.979-5.199 4.979-3.015 0-5.801-2.305-5.801-6.275z"/></svg>") no-repeat;
*/