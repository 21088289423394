.events {
	width: 100%;
}

.month {

	& .month__title {
		margin-bottom: 20px;
		padding: 15px 20px;
		font-weight: 700;
		font-size: 1.5rem;
		color: #fff;
		text-shadow:var(--text-shadow);
		text-transform: uppercase;
		background-color: var(--color-accent);
		background-image: url("../images/trash-background.png");
		background-size: cover;
	}
}

.event {
	border-bottom: solid whitesmoke 2px;
	padding-bottom: 40px;
	margin-bottom: 20px;

	& header{
		display: flex;
		margin-bottom: 20px;
	}

	& h2 {
		font-size: 1rem;
		color: #546122;
		word-wrap: break-word;
	}


	& a {
		margin-left: 35px;
		color: #8CA33C;
		font-weight: bold;
		
		&:--pocus{
			color:var(--color-primary);
			text-decoration: none;
		}
	}
}

.event-wheat {
	margin-right: 20px;
	margin-left: 5px;
	margin-top: -8px;
}

.event-time {
	display: inline-block;
}

.event-date {
	margin-left: 35px;
	margin-top: -20px;
}

.event-calendar {
	margin-top: 6px;
	margin-right: 10px;
	margin-bottom: -4px;

	& svg {
		fill: #f18b21;
	}
}

.event-clock {
	margin-right: 10px;
	margin-bottom: -4px;
	& svg {
		fill: gray;
	}
}

.event-headin-wrap {
	white-space: nowrap;
}

.event-list{
	list-style: none;
	margin: 0;
	margin-bottom: 24px;
	padding: 0;

	& li::before{
		content: "\2022";
		display: inline-block;
		width: 0.75rem;
		font-size: 1.125rem;
		line-height: 1;
		color: var(--color-primary);
	}

}

.event-detail-block{

	& p:not(:last-of-type){
		margin-bottom: 0;
	}
}

@media only screen and (max-width: 768px) {
	.events {
		padding: 20px;
	}
}

.btn--addCalendar {
	display: flex;
	align-items: center;
	width: fit-content;
	margin-bottom: 30px;
	padding-right: 33px;
	padding-left: 33px;
	background: #7A426B;
	text-transform: capitalize;

	& .icon {
		width: 10px;
		height: 10px;
		margin-left: 10px;
	}
}

.addToCalendar{
	display: none;
	
	& ul{
		list-style: none;
		margin-bottom: 30px;
		padding: 20px;
		box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .14);

		& li{

			& a{
				display: block;
				color: #757775;
			}

			& a:--pocus{
				color: #f18b21;
			}
		}

		& li:not(:last-of-type){
			margin-bottom: 10px;
		}
	}
}

.addToCalendar.is-visible{
	display: block;
}