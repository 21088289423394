.top-newsletter{
	display: none;
	background: linear-gradient(0deg,rgba(241,139,33,0.85),rgba(241,139,33,0.85)),
	url(../images/can-goods-bw.jpg);
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;

	& .container{
		padding-top: 10px;
		padding-bottom: 10px;
	}

	& p{
		font-size: 0.875rem;
		font-weight: 600;
		color:#fff;
		line-height: 1;
		text-align: center;
		text-shadow:0 2px 1px rgba(57,46,43,0.2);
	}

	& form{

		& input{
			margin-bottom: 8px;
			padding: 8px 0;
			padding-left: 10px;	
			line-height: 1;
			border:none;
			border-radius: 4px;

			&::placeholder{
				line-height: 1;
			}
		}

		& .newsletter-btn{
			width: 100%;
			padding:8px 12px;
			background-color: #000;
			border:none;
		}
	}
}

.util-nav{
	display: none;
}

@media only screen and (min-width: 1091px) {
	.top-newsletter{
		display: block;
		padding-top: 8px;
		padding-bottom: 8px;

		& .container{
			display: flex;
			align-items:center;
			justify-content:center;
			padding-top: 0;
			padding-bottom: 0;
		}

		& p{
			margin-bottom: 0;
			font-size: 1.125rem;
		}

		& form{
			display:flex;
			margin-left: 40px;

			& input{
				width: 260px;
				margin-bottom: 0;
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
				margin-top: 0;
			}

			& .newsletter-btn{
				width: auto;
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
			}
		}
	}
}

@media only screen and (max-width: 1090px) {
		.util-nav{
			display: block;
		background-color: var(--color-primary);

		& .container{
			display:flex;
			align-items:center;
			justify-content:space-between;
		}

		& .paperplane{
			padding: 15px;
			background-color: #CF8600;
		}

		& .nav-toggle{
			display: flex;
			align-items: center;
			width: 25px;
			height: 25px;
			margin-left: auto;
			text-align: right;
			z-index: 9999;
		}
	}
}

.newsletter {
	text-align: center;
	padding-top: 60px;
	padding-bottom: 80px;
	background-image: var(--global-bg-img);
	background-size: cover;
	background-repeat: no-repeat;
	background-color: rgba(246,246,246,0.5);

	& .newsletter-wrap {
		max-width: 700px;
		margin-left: auto;
		margin-right: auto;
	}

	& form {
		margin-top: 35px;
		display: flex;

		& input {
			padding-top: 10px;
			padding-bottom: 10px;
			padding-left: 15px;
			height: 45px;
			border-radius: 4px 0 0 4px;
			background-color: #fff;
			border:none;
		}

		& .newsletter-btn {
			color: #fff;
			font-weight: bold;
			padding: 10px 15px;
			background-color: var(--color-accent);
			border-radius: 0 4px 4px 0;
		}
	}
}

@media only screen and (max-width: 768px) {
	.newsletter {
		padding-top: 35px;
		padding-bottom: 35px;
	}

	.newsletter form {
		flex-direction: column;
		padding-left: 10px;
		padding-right: 10px;

		& input {
			margin-bottom: 20px;
			border-radius: 4px;
		}
		& .newsletter-btn {
			color: #fff;
			font-weight: bold;
			padding: 10px 15px;
			background-color: var(--color-accent);
			border-radius: 4px;
			width: 100%;
			height: 45px;
		}
	}
}