
/*----- This is the CSS for tab navigation -----*/

body:not(.user-is-tabbing) button:focus,
body:not(.user-is-tabbing) input:focus,
body:not(.user-is-tabbing) select:focus,
body:not(.user-is-tabbing) textarea:focus,
body:not(.user-is-tabbing) a:focus,
body:not(.user-is-tabbing) .child-trigger:focus {
  box-shadow: none;
}

button:focus,
input:focus,
select:focus,
textarea:focus,
a:focus,
.child-trigger:focus {
-webkit-box-shadow: 0px 0px 7px 9px rgba(204,227,102,1);
-moz-box-shadow: 0px 0px 7px 9px rgba(204,227,102,1);
box-shadow: 0px 0px 7px 9px rgba(204,227,102,1);
}


