.btnGroupBlock {
  padding-top: 60px;
  padding-bottom: 60px;
}

@media only screen and (min-width: 768px) {
  .btnGroupBlock__wrap {
    display: flex;
    margin-left: -20px;
    margin-right: -20px;
    flex-wrap: wrap;
    justify-content: space-around;
  }
}

.btnGroupBlock__btn{
  position: relative;
  display: block;
  border-radius: 50px 0;
  margin-bottom: 10px;
  padding: 20px;
  font-size: 20px;
  font-weight: 900;
  color: #7A426B;
  text-align: center;

  &::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50px 0;
  }

  &:--pocus{
    text-decoration: none;
    
    &::after{
      background-color: rgba(0, 0, 0, 0.0075);
    }
  }
}

@media only screen and (min-width: 768px) {
  .btnGroupBlock__btn {
    flex: none;
    width: 25%;
    margin-bottom: 0;
  }
}