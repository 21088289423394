.hero {
	background-color: #E5F2FF;
	min-width: 100%;

	& .container{
		max-width: 1180px;
		padding-top: 50px;
		padding-bottom: 50px;
		padding-left: 80px;
		background-size: cover;
		background-repeat: no-repeat;
	}

	& .btn--hero {
		padding: 12.5px 25px;
		margin-right: 15px;
		margin-top: 20px;
		background-color: #764068;
		text-align: center;
		font-weight: bold;
	}
}

.hero__content {
	max-width: 480px;
	margin-left: 0;
	padding: 30px 40px;
	font-size: 16px;
	line-height: 1.25rem;
	color: #fff;
	background-color: rgba(0, 0, 0, 0.7);
	border-radius: 4px;

	& p{
		font-size: 1rem;
		font-weight: 600;
		margin-bottom: 0;
	}
}

.mobile-hero-slider {
	min-height: 115px;
	& img {
		min-height: 115px;
	}
}


.mobile-hero {
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: #392E2B;
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 40px;
	padding-bottom: 20px;

	& .hero__content {
		background-color: inherit;
		padding: 0;
	}
}

.mobile-hero--btn {
	background-color: #764068;
	text-align: center;
	margin-top: 10px;
	width: 80%;
	font-weight: bold;
}

@media only screen and (min-width: 769px) {
	.mobile-hero {
		display: none;
	}
	.mobile-hero-slider {
		display: none;
	}
}

@media only screen and (max-width: 769px) {
	.hero {
		display: none;
	}
}

/* --- hero banner - brown banners underneath primary-nav --- */
.hero-bnr {
	color: #fff;
	padding-top: 20px;
	padding-bottom: 20px;
	background-color: var(--color-secondary);
	background-image: url('/assets/images/trash-background.png');

	& h1{
		font-size: 20px;
		text-shadow:var(--text-shadow);
	}
}

@media only screen and (min-width: 768px) {
	.hero-bnr{
		padding-top: 34px;
		padding-bottom: 34px;
	}
}
	