.menu{
	position: relative;
	display: block;
	width: 100%;
	height: 4px;
	margin-bottom: 0;
	padding: 0;
	font-size: 0;
	background-color: white;
	border-radius: 0px;
	
	transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

	&:before, &:after{
		content:'';
		position: absolute;
		top:0; right:0;
		display: block;
		width: 100%;
		height: 4px;
		background-color: white;
	}

	& .link{
		color:green;
	}
}

.menu:before{
	top:-7px;
}

.menu:after{
	top:7px;
}

.menu.is-active{
	transform:rotate(45deg);

	&:before{
		top:0;
		opacity:0;
	}
}

.menu.is-active:after{
	top:0;
	transform:rotate(-90deg);
}
