
.listingBlock{
  padding:65px 0;
}

@media only screen and (min-width: 768px) { 
  .listingBlock__wrap{
    display:flex;
    margin-right:-20px;
    margin-left:-20px;
  }
}

.listingBlock__card{
  margin-bottom:40px;
}

@media only screen and (min-width: 768px) {
  .listingBlock__card{
    flex:none;
    width:50%;
    margin-bottom:0;
    padding:0 20px;
    border-right:1px solid #E1E1E1;
    &:last-of-type{
      border:none;
    }
  }
}

@media only screen and (min-width: 768px) {
  .listingBlock__card--third{
    width:33.3333%;
  }
}

.listingBlock__img{
  margin:0 0 26px;
}