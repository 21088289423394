.primary-nav{
	font-size: 1.1em;
	color: white;
	background-color: white;

	& .container{
		display:flex;
		align-items:flex-end;
		justify-content:space-between;
		padding: 30px;
		width: 100%;
		max-width:1180px;

		&:after {
			display: none;
		}
		& .logo2 {
			margin-bottom: 0;
		}
	}
}

.logo{
	width: 200px;
}

.logo-wrap {
	display: flex;
	align-items: flex-end;
	justify-content: flex-start;
}

.primary-nav__list{
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 0;
	font-size: 1em;
	max-width: 800px;

	& li{
		font-weight: 600;
		margin-bottom: 2px;
		color: #757775;
		font-weight: bold;
		text-transform: uppercase;
		padding-right: 30px;


		& a{
			display: block;
			color: inherit;
			text-decoration: none;
			letter-spacing: 0.1px;
			& .icon{
				width:0.6rem;
				height:0.6rem;
			}
		}

	}
	& .nav-button {
			color: white;
			padding-right: 15px;
		}
}
.list-item:hover {
			transition: all 200ms linear;
			color: #e98322;
		}

.primary-nav__social {
	color: white;
	padding: 20px;

	& li + li {
		margin-left: 15px;
	}

	& li {
		display: inline-block;
		margin: 0;
	}

	& svg {
		width: 20px;
		height: 20px;
	}
}

.primary-nav__toggle{
	display: none;
}

@media only screen and (min-width: 768px) {
	.nav-button {
		background-color: #8CA33C;
		padding: 10px 15px;
		border-radius: 4px;
	}

	.nav-button--orange{
		background-color: #e98322;
	}
	
	.mobile-logos {
		display: none;
	}
}

@media only screen and (max-width: 1090px) {
	.primary-nav {
		display:none;
	}

	.mobile-logos {
		width: 100%;
		display: flex;
		align-items: flex-end;
		justify-content: center;
		padding: 20px;

	}

	.primary-nav__list{
		position: fixed;
		top:60px; left:0;
		z-index:100;
		display: block;    
		width: 100%;
		height: 100vh;
		margin-bottom: 0;
		text-align: left;
		visibility: hidden;
		opacity:0;
		overflow-y:auto;
		background-color: #FFFFFF;
		transition: all .5s ease;
		/* nested */

		&.is-opened{
			visibility: visible;
			opacity:1;
			margin: 0;
			padding-left: 0;
		}

		& > li{
				margin: 0;
				color: #706765;
				border-bottom: #E1E1E1 solid 1px;
				list-style-type: none;
				width: 100%;
				
			
			& > a {
				display: block;
				margin-left: 0;
				padding: 15px 0px 15px 30px;
			}
		}
		& li:last-of-type {
				border-bottom: transparent solid 1px;
				& a {
					padding-left: 0;
				}
			}
	}
	.mobile-nav-button {
		display: flex;
		justify-content: center;
		padding-left: 0;
	& a {
		width: 140px;
		background-color: #8ca33c;
		border-radius: 4px;
		text-align: center;
		font-weight: 900;
		margin-top: 20px;
		padding: 10px;
		color: white !important;
		}
	}

	.primary-nav__toggle{
		display: flex;
		align-items: center;
		width: 25px;
		height: 25px;
		margin-left: auto;
		text-align: right;
		z-index: 9999;
	}

	.is-opened + .primary-nav__toggle{
		position: relative;
	}
}

@media only screen and (min-width: 1090px) {
	.primary-nav__list{
		display:flex;
		align-items:center;

		& li {
			display: inline-flex;
			font-size: 0.9375rem;
		}

		& > li:not(:first-of-type){
			margin-left: 32px;
		}
	}
	.mobile-nav {
		display:none;
	}
}


.mobile-nav {
	background-color: white;
}

.mobile-nav__list {
	display:none;
	list-style: none;
	padding-left: 0;
	margin-bottom: 0;
	& li {
		padding: 15px 0px 15px 20px;
		border-bottom: #E1E1E1 solid 1px;
		width: 100%;
	}
	& li a {
		color: #546122;
		font-weight: 700;
		text-transform:uppercase;
	}
	& li:last-of-type {
		border-bottom: transparent solid 1px;
		/* padding-left: 0; */
		& a {
			padding-left: 0;
			padding-right: 0;
			margin-top: 10px;
		}
	}
}

.mobile-nav__list--child{
	& li a,
	& li button{
		color:#706765;
		text-transform:capitalize;
	}
	& li button{
		display:flex;
		justify-content:center;
		align-items:center;
		padding-left:0;
		background:transparent;
	}
}

.mobile-nav__list .nav-child-item {
	display: inline-block;
	& .nav-child-trigger {
		display: inline-block;
		margin-left: 30px;
		height: 20px;
		background-color: white;
		width: 30px;
	}
}

.mobile-nav__list .child-item{
	display:flex;
	justify-content:space-between;
	flex-wrap:wrap;
	align-items:center;
}

.nav-child-nav{
	width:100%;
}


.nav-child-nav {
	display: none;
	padding-left: 20px;
	border-top: #E1E1E1 solid 1px;
	margin-top: 20px;
}


.nav-child-nav li {
	padding-left: 0;
}

/* testing */

@media only screen and (min-width:768px) {

	.list-item{
		display:flex;
		align-items:center;
	}

	.btn-dropdown {
    overflow: hidden;
  }

	.dropbtn,
	.dropbtn.active{
		font-weight:700;
		color: #5c5b5a;
		background:transparent;

		& span{

			& .icon{
				width:0.6rem;
				height:0.6rem;
				cursor:pointer;
			}
		}
	}

	.dropdown-content {
    position: absolute;
		top:113px;
    z-index: 12;
    display: none;
    min-width: 230px;
    background-color: #f9f9f9;
		overflow: visible;

		& li{
			display:block;

			& a {
				display: block;
				margin:0;
				padding: 0.5rem 1rem;
				border-bottom:none;
				text-align: left;
				white-space: nowrap;

				&:--pocus{
					color:#e98322;
				}
			}
		}
  }

	/* Show the dropdown menu on hover */
	.dropdown:hover .dropdown-content,
	.dropdown:focus .dropdown-content,
	.dropdown.focus .dropdown-content {
		display: block;
		box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.3);
	}

	.hasUrl{
		position: relative;
	}
	li.hasUrl{
		padding-right:0;
	}

	li.hasUrl--children a{
		display:flex;
		justify-content:space-between;
		align-items:center;
	}

	.grandChildList{
		position: absolute;
		top: 0;
		left: 230px;
		z-index: 13;
		display: none;
		min-width: 260px;
		background-color: #f4f4f4;
		box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.3);

		& li{
			display:block;

			& a {
				display: block;
				margin:0;
				padding: 0.5rem 1rem;
				border-bottom:none;
				text-align: left;
				white-space: nowrap;
				
				&:--pocus{
					color:#e98322;
				}
			}
		}
	}
	
	.hasUrl:hover .grandChildList{
		display: block;
	}

}


