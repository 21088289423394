/* base container styles */
.container,
.content {
  position:relative;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}

.container{
  max-width:1020px;
}

.content{
  max-width:1180px;
  padding-left: 0;
  padding-right: 0;
}

/* custom container styles */

.container--master {
	max-width: 1440px;
  padding: 0;
}

.container--flex {
  display: flex;
}


/*
@media only screen and (max-width: 768px) {
  .container {
    padding-left: 5px;
    padding-right: 5px;
  }
}
*/

