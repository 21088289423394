a{
	color: var(--color-primary);
	text-decoration: none;
	transition:all 0.2s;

	&:--pocus {
		text-decoration: underline;
	}
}

/* overrides what is in base/_base.css */
a[href]:empty::before {
  display: none;
}