
.blog-container {
	display: flex;
	background-color: #FFFFFF;
	padding-bottom: 60px;
	padding-top: 60px;
	flex-wrap: wrap;
	justify-content: flex-start;
	width: 100%;
	& .blog-card {
		margin: 0 5px 5px 20px;
		width: 30%;
		padding-bottom: 20px;
		margin-bottom: 10px;
		box-shadow: 0 2px 4px 0 rgba(147,147,147,.15);;
	}
	& .blog-content {
		margin-bottom: 10px;
		padding-left: 20px;
		padding-right: 20px;
		& h3 {
			color: #546122;
			margin-bottom: 10px;
			margin-top: 15px;
			font-size: 1.25rem;
			font-weight: 700;
		}
	}
	& a {
			color: #E98323;
			text-decoration: none;
			font-weight: 700;	
			padding-left: 20px;
		}
}

.meta {
	display: flex;
	& p {
		margin-bottom: 0;
		margin-right: 15px;
	}
}

.meta.icon:nth-child(1) {
	margin-right: 10px;
}

.clock {
	& svg {
		height: 14px;
		width: 14px;
		margin-bottom: -2px;
		margin-right: 5px;
		fill: #91a14c;
	}
}

.profile {
	margin-left: 10px;
	& svg {
		height: 14px;
		width: 14px;
		margin-bottom: -2px;
		margin-right: 3px;
		fill: #91a14c;
	}
}

@media only screen and (max-width: 829px) {
	.blog-container {
		justify-content: center;
		& .blog-card {
			max-width: 340px;
		}
	}
}


@media only screen and (max-width: 768px) {
	.blog-container {
		padding-top: 0;
		padding-bottom: 30px;
		width: 100%;
		& .blog-card {
			width: 100%;
			margin: 20px 5px 0 5px;
		}
	}
}