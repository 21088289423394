.groceryBlock{
  position:relative;
  & h2{
    margin-bottom:30px;
  }

  & h3{
    margin-bottom:10px;
    & span{
      font-weight:400;
    }
  }
}


.loader{
  position:absolute;
  top:0;
  left:0;
}

.groceryBlock__list{
  display:flex;
  flex-wrap:wrap;
  margin-right:-20px;
  margin-left:-20px;
  margin-bottom:40px;
  padding-left:0;
}

.groceryBlock__location{
  display:flex;
  width:33.3333%;
  margin-bottom:20px;
  padding:0 20px;
  list-style:none;
  & .icon{
    width:8px;
    height:19px;
    margin-right:20px;
  }
}


.groceryBlock__content{
  width:100%;
  & h5{
    font-size:14px;
    font-weight:700;
    color:#8CA33C;
  }
  & p{
    margin:0;
  }
  & address{
    margin-bottom:0;
  }
}
