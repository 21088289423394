.container--small{
  max-width:680px;
}

.bg-extend{
  position: relative;
  right: 50%;
  left: 50%;
  width: 100vw;
  margin-left: -50vw;
  margin-right: -50vw;
}

.bg--yellow {
  background: #FDF7E9;
}

.bg--green {
  background: #F3F6EB;
}

.bg--blue {
  background: #F3F7FB;
}