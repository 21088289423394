.column--events{
  width:100%;
}
  
.calendar{
  width:100%;
  margin-top: 10px;
  border-collapse: initial;
  border-spacing: 2px;

  & .date{
    padding:0;
  }

  & .date__day{
    padding:4px;
  }

  & .event{
    padding:6px;
    font-weight: 600;
    font-size: 12px;
    line-height: 1.2;

    & a{
      margin:0;
      color:var(--color-primary);
      transition: all 0.3s ease-in-out;

      & strong{
        word-wrap:break-word;
      }

      &:hover{
        color: #a7d7ff;
        text-decoration: none; 
      }
    }
  }

  & .event:nth-of-type(odd){
    background-color:rgb(238, 238, 238);
  }
}

.back-to-calendar{
  color:#006b37;
}

.calendar thead{
  & tr{
    font-weight: 600;
    text-align: center;
    background-color: var(--color-secondary);
  }
  & td{
    color: #fff;
  }
}
  
.calendar tr{
  background-color: #f9f9f9;
}
  
.calendar tbody td{
  vertical-align:top;
  width: 50px;
  height: 30px;
  padding: 4px;
  font-weight: 600;
  font-size: 0.8571rem;
  color:#404040;
}
  
@media only screen and (min-width: 485px){
  .calendar tbody td{
    vertical-align:top;
    width: 107px;
    height: 107px;
    padding: 8px;
    font-size: 1.2rem;
    border:none;
  }
}

.column--events{
  flex-direction: row-reverse;
}
  
.event-period{
  display: flex;
  align-items: center;
  padding: 12px 0 16px;

  & p{
    margin-bottom:0;
  }

  & ul{
    display: flex;
    margin-right:-1rem;
    margin-left:-1rem;
    justify-content: space-between;
    margin-left: 10px;

    & li{
      flex:none;
      padding:0 1rem;
    }
  }
}

.events-scroller-wrap{
  margin-bottom:52px;
  padding: 10px 25px 8px;
}
  
.event-scroller{
  display: flex;
  justify-content: space-between;
  border-top:1px solid #e1e1e1;
  border-bottom:1px solid #e1e1e1;

  & .btn{
    display:flex;
    align-items:center;
    justify-content: center;
    width: fit-content !important;
    color:#8ca33c;
    text-align: center;
    text-transform: none;

    & .icon{
      width:1.2rem;
      margin-left:0.5rem;
      opacity:0.5;
    }
  }
}
  
.icon--left-chevron {
  margin-right:0.5rem;
  transform: scaleX(-1);
}

.event-scroller .current {
  padding-top:8px;
  font-weight:700;
  color:#2e2e2e;
}

.events__pagination{
  padding-top:23px;
}
  
.column-wrap-calendar{
  width:100%;
}

.calendar-entry .table{
  display: table;
  width: 100%;
  margin-top: 0;
  border-collapse: collapse;
}

.calendar-entry .table__row{
  border-bottom: 2px solid #ddd;
}

@media only screen and (min-width: 892px){
  .table__row {
    display: table-row;
  }
}
  
.calendar-entry .table__head{
  padding:8px;
  font-weight:700;
  color:#2e2e2e;

  & a{
    color:#2e2e2e;
    text-decoration:none;
  }
}

.calendar-entry .table__row .table__head:nth-of-type(1){
  display: table-cell;
  width: 35%;
}
.calendar-entry .table__row .table__head:nth-of-type(2){
  display: table-cell;
  width: 65%;
}

.calendar-entry .table__row--events{
  width:100%;
  background: #f9f9f9;
}

.calendar-entry .table__cell{
  padding:8px;
}

.calendar-entry .table__cell:nth-of-type(1){
  display:table-cell;
  margin-top: 0;
  width: 40%;
}
  
.calendar-entry .table__cell:nth-of-type(2){
  display:table-cell;
  margin-top: 0;
  width: 60%;

  & a{
    transition: all 0.3s ease-in-out;

    &:--pocus{
      color: #a7d7ff;
      text-decoration: none;
    }
  }
}
  
.calendar .today{
  position: relative;

  &::after{
    content: '';
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
    height: 5px;
    background-color: var(--color-primary);
  }
}
  
.date__day{
  font-size:1.2rem;
}

.event-details-list{
  margin-bottom:4rem;
  & li{
    margin-bottom:1rem;
    & p{
      & strong{
        color:#2e2e2e;
      }
    }
  }
}

.imgBlock--calendar-event{
  padding-top:50px;
}

.event__form{
  padding-bottom:75px;

  & h2{
    margin-bottom: 16px;
    font-size: 20px;
    color: var(--color-accent-dark);
  }
}

.events__listing{
  min-height: 400px;
}