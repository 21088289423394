
/*--- this code hides and shows the skip-to-main-
content button on each page based on focus */
#skip_nav {
	display: none;
	padding:10px;
	position: absolute;
	color:white;
	border-bottom-right-radius:8px;
	background-color: #8ca33c;
	border-bottom-right-radius: 4px;
	-webkit-transition: top 1s ease-out;
    transition: top 1s ease-out;
    z-index: 100;
    & a {
    	color: white;
    	text-decoration: none;
    }
}

#skip_nav:focus {
	display: block;
}

.no-focus:focus {
	box-shadow: none;
}


.heading {
	text-align: center;

	& h2 {
		color: var(--color-accent-dark);
		margin-bottom: 15px;
		font-size: 27px;
		font-weight: 600;
	}

	& .wheat {
		margin-left: 5px;
		margin-right: 5px;
	}
	& p {
		font-weight: 600;
		font-size: 13px;
	}
}

.wheat-break{
	color: var(--color-primary);
	line-height: 1;

	& span{
		position: relative;
		display: inline-block;

		&::before,
		&::after{
			content:'';
			position: absolute;
			top:50%;
			width: 20px;
			height: 1px;
			background-color: var(--color-primary);
		}

		&::before{
			right:100%;
			margin-right: 5px;
		}

		&::after{
			left:100%;
			margin-left: 5px;
		}
	}
}

.vision {
	background-color: white;
	text-align: center;
	padding-top: 65px;
	padding-bottom: 60px;
	font-weight: bold;

	& .vision-wrap {
		display: flex;
		font-weight: bold;
		justify-content: space-around;
		margin-top: 50px;

		& article{
			flex-basis:20%;
		}


		& article img {
			display: block;
			margin-bottom: 20px;
		}
		& article a {
			color: inherit;
			text-decoration: none;
			height: 100%;
		}
		& article p {
			font-size: 16px;
		}
	}
}

@media only screen and (max-width: 768px) {
	.vision {
		padding-bottom: 20px;
		padding-top: 40px;
	}

	.vision-wrap {
		flex-direction: column;
		& article {
			margin-bottom: 15px;
		}
	}

	.heading {
		padding-left: 20px;
		padding-right: 20px;

		& h2{
			font-size: 22px;
			letter-spacing: 0.2px;
		}
	}
	
}

.video {
	text-align: center;
	padding-top: 70px;
	padding-bottom: 30px;
	font-weight: bold;
	background-image: var(--global-bg-img);
	background-size: cover;
	background-repeat: no-repeat;
	background-color: rgba(246,246,246,0.5);
}

.home-events {
	background-color: #fff;
	text-align: center;
	padding-top: 65px;
	padding-bottom: 90px;
}

.home-events-wrap {
	display: flex;
	margin-top: 50px;
	flex-direction: row-reverse;
	justify-content: center;

	& article {
		max-width: 340px;
		text-align: left;
		padding-left: 20px;
		padding-right: 20px;
		

		& h4 {
			margin-top: 30px;
			margin-bottom: 10px;
			color: var(--color-accent-dark);
			font-weight: bolder;
			font-size: 20px;
		}

		& a {
			color: #var(--color-primary);
			font-weight: 600;
		}
	}

	& article:nth-last-of-type(1) {
		border-right: solid transparent 2px;
	}
}

.card-img{
	margin: 0;
	margin-bottom: 1rem;
}

.card-content {

	& h3{
		font-size: 20px !important;
	}
}

@media only screen and (min-width: 768px) {
	.mobile-events-wrap {
		display: none;
	}
	.home-events-button {
		display: none;
	}
}

@media only screen and (max-width: 768px) {
	
	.video {
		padding-top: 30px;
		padding-bottom: 20px;
	}
}

@media only screen and (max-width: 768px) {
	.home-events-wrap {
		flex-direction: column;
		max-width: 100%;

		& article {
			margin-bottom: 20px;
			max-width: 100%;
			border-right: solid transparent;
		}
		& article img {
			width: 100%;
		}
	}
}

.partners {
	background-color: rgba(246,246,246,0.5);
	text-align: center;
	padding-top: 60px;
	padding-bottom: 60px;

	& .partner-logos {
		margin-top: 40px;
		display: flex;
		justify-content: space-around;
		& a{
			display: block;
			height: 100%;
			max-height: 100%;
			flex-basis: auto;
			padding: 10px;
		}
		& a img {
			height: 100%;
			width: 100%;
			flex-grow: 1;
		}
	}
}

.mobile-partner-logos {
	display: none;
}

@media only screen and (max-width: 768px) {
	.partners .partner-logos {
		display: none;
	}
	.mobile-partner-logos {
		display: block;
		margin-top: 30px;
		& img {
			width: 200px;
		}
	}
}


.structure {
	& img {
		margin-bottom: 20px;
		width: 100%;
		max-height: 300px;
	}
	& h3 {
		margin-bottom: 15px;
		color: #546122;
		font-weight: bold;
	}
}

.slider {
	position: relative;
	margin: auto;
}



.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
}

.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

.prev:hover, .next:hover {
  background-color: rgba(0,0,0,0.8);
  text-decoration: none;
}

.dot-wrap {
z-index: 50;
margin-top: -30px;
}

.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  color: black;
  border-radius: 50%;
  display: inline-block;
  
}

.active, .dot:hover {
  background-color: #717171;
}

.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

@-webkit-keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}

@keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}

.hidden {
	display: none;
}

.loader {
	position: fixed;
	z-index: 9999999999;
	top:0;
	left:0;
	width: 100%;
	height: 100%;
	max-height:	200px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: white;
}

.loader > img,
.loader > svg {
	width: 100px;
	height: 100px;
}

.loader.hidden {
	animation: fadeOut 1s;
	animation-fill-mode: forwards;
}

@keyframes fadeOut {
	100% {
		opacity: 0;
		visibility: hidden;
	}
}

.alert-bnr {
	display: none;
	background-color: #cc2a12;
	color: #fff;
	width: 100%;
	z-index: 9999;
	padding: 10px;
}

.alert-bnr.opened {
	display: block;
}

.alert-wrap {
	display: flex;
	margin-left: auto;
	margin-right: auto;
	max-width: 1000px;
	justify-content: center;
	align-items: center;

	& p {
		margin-bottom: 0;
		margin-right: 20px;
		display: inline-flex;
		align-items: center;
		font-size: 16px;
		font-weight: bold;
		text-shadow: var(--text-shadow);
	}

	& .btn {
		background-color: #ad0c00;
		padding: 10px;
		text-align:center;
		width: 100%;
		max-width: 200px;
	}
}

.alert-close {
	position: absolute;
	right: 30px;
	top: 28px;
	margin-top: -8px;
	font-weight: bold;
	font-size: 16px;
	color: #fff;

	&:hover {
		cursor: pointer;
	}

	& svg{
		width: 1em;
		height: 1em;
	}
}

@media only screen and (max-width: 768px) {
	.alert-wrap {
		flex-direction: column;
		text-align: center;
		& .btn {
			margin-top: 10px;
		}
	}
	.alert-close {
		right: 10px;

	}
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.sticky + .top-newsletter {
  margin-top: 56px;
}

.sticky + .util-nav {
  margin-top: 56px;
}

.closed + .top-newsletter {
	margin-top: 0;
}

.closed + .util-nav {
	margin-top: 0;
}


.bg-extend {
	position: relative;
	right: 50%;
	left: 50%;
	width: 100vw;
	margin-left: -50vw;
	margin-right: -50vw;
}