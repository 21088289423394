/* --- inline images --- */

.inline-image {
  margin: 0;
  margin-bottom: 20px;

  & img {
    width: 100%;
    max-width: 100%;
  }

  & figcaption {
    margin-top: 20px;
    font-style: italic;
    text-align: center;
  }
}

@media only screen and (min-width: 768px) {
  .inline-image {

    & img {
      width: auto;
    }
  }

  .inline-image--full {
    & img {
      width: 100%;
    }
  }

  .inline-image--left{
    float: left;
    max-width: 50%;
    margin-right: 20px;
  }

  .inline-image--right{
    float: right;
    max-width: 50%;
    margin-left: 20px;
  }
}

.inline {
  & p {
    display: inline-block;
  }
  & input {
    display: inline-block;
  }
}

/* responsive video */
.vid-wrap{
  position:relative;
  width: 100%;
  height:0;
  margin-top: 20px;
  margin-bottom:20px;
  padding-bottom:56.25%;
  box-shadow: var(--box-shadow)
}

@media only screen and (min-width: 768px) {
  .vid-wrap {
    margin-top:50px;
    margin-bottom: 50px;
  }
}

.vid-wrap iframe,
.vid-wrap object,
.vid-wrap embed {
  position: absolute;
  top: 0;left: 0;
  width: 100%;
  height: 100%;
}
.vid-wrap {
  border: white 10px solid;
}

.vid-wrap--noBorder{
  border:none;
}

/* --- page break --- */

.page-break{
  width: 100%;
  margin: 0;
  border:0;
  clear:both;
}