	
.contact {
	background-color: white;
	padding-top: 30px;
	padding-bottom: 30px;
	width: 100%;
	& h3 {
		color: #546122;
	}
}

.form-button {
	background-color: #8ca33c;
	font-weight: bold;
}

.location-svg {
	margin-right: 10px;
	margin-bottom: -10px;
	& svg {
		fill: #F18B21;
	}
}

.phone-svg {
	margin-right: 10px;
	margin-bottom: -4px;
	& svg {
		fill: #F18B21;
	}
}

.contact-columns {
	display: flex;
	justify-content: space-between;
	padding-top: 30px;
	padding-bottom: 10px;
	margin-bottom: 40px;


	& article {
		border-right: solid whitesmoke 2px;
		width: 100%;
		text-align: left;
		margin-right: 20px;
		& a {
			color: inherit;
		}
	}

	& article:nth-last-of-type(1) {
		border-right: solid transparent 2px;
	}
	& h4 {
		font-size: 16px;
		color: #706765;
		margin-bottom: 10px;

	}
}

.contact-location p {
	margin-top: -20px;
}

	@media only screen and (max-width: 768px) {
	.contact-columns {
			flex-direction: column;
			justify-content: space-between;
			text-align: left;
			padding-left: 20px;
			padding-right: 20px;
			margin-bottom: 0;
			& article {
				border-bottom: solid whitesmoke 1px;
				border-right: solid transparent;
				margin-bottom: 30px;
			}
		}
	}
