.highlightBlock {
  margin-bottom: 60px;

  & .btn {
    margin-bottom: 20px;
  }
}

.highlightBlock__img {
  width: 100%;
  max-width: 680px;
  margin: 0 auto;
  padding-top: 25px;
  padding-bottom: 20px;

  & img {
    border-radius: 50px 0;
  }
}

