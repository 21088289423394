h1, h2, h3, h4, h5, h6{
  margin:0;
}

h1, .alpha{
  font-size: 3rem; /* 48px */
}

h2, .beta{
  font-size: 2rem; /* 32px */
}

h3, .gamma{
  font-size: 1.5rem; /* 24px */
}

h4, .delta {
  font-size: 16px; /* 20px */
}

h5, .epsilon{ 
  font-size:1rem; /* 16px base */
}


small, .milli{
  font-size: 0.625rem; /* 10px */
}

.bold {
  font-weight: 700;
}

.side-bar-title {
  font-size: 14px;
  text-align: left;
  color: #546122;
  font-weight: 900;
}

/*

font-size using rem if default is 16

10px = 0.625rem
11px = 0.6875rem
12px = 0.75rem
13px = 0.8125rem
14px = 0.875rem
15px = 0.9375rem
16px = 1rem (base)
18px = 1.125rem
20px = 1.25rem
22px = 1.375rem
24px = 1.5rem
26px = 1.625rem
28px = 1.75rem
30px = 1.875rem
32px = 2rem
34px = 2.125rem
36px = 2.25rem
38px = 2.375rem
40px = 2.5rem


*/