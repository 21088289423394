p, ul, ol, address{
	margin: 0 0 20px;
}

p, ul, ol{
	font-size: 0.875rem;
}

.first-word {
	font-weight: 700;
}

.truncate {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.prefix {
  font-weight: bolder;
  text-transform: uppercase;
}

address {
	font-style: normal;

	& a {
		color: var(--color-primary);

		&:--pocus {
			color: var(--color-secondary);
			text-decoration: none;
		}
	}

	& p {
		margin-bottom: 0;
    word-wrap: break-word;
	}
}
