.pantry-form {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 20px;
	align-items: center;
	& label {
		margin-bottom: 0;
		font-weight: bold;
	}
	& p {
		flex:none;
		display: block;
		width: 100%;
		margin-bottom: 6px;
		font-weight: 700;
	}
	& input {
		background-color: whitesmoke;
		border-radius: 4px;
		max-width: 250px;
		border: none;
	}
	& select {
		background-color: whitesmoke;
		border-radius: 4px;
		border: none;
		max-width: 100px;
		margin-left: 16px;
	}

	& .field{
		flex: none;
		width: 100%;
		margin-bottom: 0;
	}
}

.pantry-button {
		margin-top: 20px;
		margin-bottom: 20px;
		width: 150px;
		background-color: ;
	}

.pantry-result {
	display: flex;
	border-bottom: whitesmoke 2px solid;
	padding-top: 20px;
	padding-bottom: 20px;
	margin-bottom: 10px;
}

.pantry-location {
	display: flex;
	justify-content: space-between;
	width: 100%;

	& > div{
		flex-basis:50%;
	}

	& .pantry-address .epsilon {
		font-size: 14px;
		color: #8CA33C;
		font-weight: 700;
		margin-bottom: 20px;
	}
}

.pantry-wheat {
	max-width: 20px;
	height: 20px;
	margin-right: 10px;
}

@media only screen and (max-width: 768px) {
	.pantry-result {
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		padding-left: 15%;
		padding-right: 15%;
	}

	.result-wrap {
		margin-top: 100px;
	}

	.pantry-location {
		flex-direction: column;
		width: 70%;
		margin-left: auto;
		margin-right: auto;
	}
}

@media only screen and (min-width: 768px) {
	.pantry-form{

		& input {
			margin-left: 16px;
		}

		& .field{
			width: 33.3333%;
		}
	}
}