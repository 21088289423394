.social-bar {
	position: fixed;
	left: -40px;
	top: 25%;
	z-index: 100;
	& ul {
		list-style: none;
		& li{
			padding:10px;
			& svg{
				height: 25px;
				width: 25px;
				color:#fff;
			}
		}
	}
}

.facebook{
	background: #3b5998;
}

.twitter{
	background: #000;
}

.tiktok{
	background: #000;
}

.threads{
	background: #000;
}

.instagram{
	background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
}

.youtube{
	background:#CD332E;
}

@media only screen and (min-width: 1088px) {
	.icon-bar {
		display: none;
	}
}

@media only screen and (max-width: 1088px) {
	.social-bar {
		display: none;
	}
	/*
	.icon-bar {
		display: flex;
		position: fixed;
		bottom: 0;
		width: 100%;
		z-index: 101;
		& div {
			text-align: center;
			background-color: #E98323;
			color: white;
			width: 100%;
			border-right: solid navajowhite 2px;
			padding-top: 10px;
			font-weight: 700;
			font-size: 9.8px;
		}
		& div a {
			color: white;
			text-decoration: none;
			height: 100%;
			width: 100%;
		}
		& div img {
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 7px;
		}
		& div p {
			margin-bottom: 10px;
		}
	}
	*/
}

@media only screen and (min-width: 1088px) {
	.mobile-social-bar {
	display: none;
}
}

@media only screen and (max-width: 1088px) {
		.mobile-social-bar {
		display: flex;
		width: 100%;
		justify-content: center;
		padding-bottom: 20px;
		padding-top: 10px;
		& a{
			display:block;
			padding:5px;
			& svg {
				height: 25px;
				width: 25px;
				margin: 2.5px;
				color:#fff;
			}
		}
	}
}






