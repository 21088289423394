.textImgBlock{
  padding:40px 0;
}

@media only screen and (min-width: 768px) { 
  .textImgBlock__wrap{
    display:flex;
    margin-right:-20px;
    margin-left:-20px;
  }
}

.textImgBlock__img{
  margin:0 0 20px;
}

@media only screen and (min-width: 768px) { 
  .textImgBlock__img{
    order:1;
    flex:none;
    width:60%;
    margin:0;
    padding:0 20px;
  }
}

.textImgBlock--right{
  order:3;
}

.textImgBlock__content{
  & h2{
    font-size:27px;
    font-weight:900;
    color:var(--color-accent-dark);
  }
  & p{
    margin-bottom:0;
    padding-top:20px;
    font-size:14px;
    color:var(--color-text);
  }

  & a{
    position:relative;
    display: inline-block;
    width: fit-content;
    font-size:16px;
    font-weight:900;
    color: #F18B21;
    line-height:1.2;
  }
}

@media only screen and (min-width: 768px) { 
  .textImgBlock__content{
    order:2;
    flex:none;
    width:40%;
    padding:0 20px;
  }
}