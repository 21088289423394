.field-group {
	display: block;

	& > * {
		margin-bottom: 20px;
	}

	& label{
		display: block;
		width: 100%;
	}
}

.field {
	flex-basis:0;
	flex-grow:1;
	flex-shrink:1;
	display: block;
	margin-bottom: 20px;
}

.field--button{
	flex:none;
}

.error-list {
	padding: 0;
	margin: 0;
	list-style: none;
}

@media only screen and (min-width:768px) {
	.field-group {
		display: flex;
		
		flex-wrap:wrap;
		justify-content: space-between;
		margin-left:-0.75rem;
		margin-right: -0.75rem;
	}
}

.form--btn {
	width: 100px;
	background-color: #8ca33c !important;
	font-weight: bold;
	height: 40px;
	color: white;
}

@media only screen and (max-width: 768px) {
	.form--btn {
		width: 100%;
	}
}

.form {
	max-width: 300px;

	& .form--btn {
		margin-top: 10px;
	}
}

/*Honeypot field. Do not use with .field*/

.keepswimming {
  display: block;
  left: -9999px;
  position: absolute;
}

.unflex-checkbox {
	flex: none;
	width: 33.3333%;
	flex-direction: column;
}

