button,.btn {
	display: inline-block;
	margin: 0;
	padding: 12px;
	color: #fff;
	font-weight: 700;
	font-family: inherit;
	font-size: inherit;
	letter-spacing: inherit;
	line-height: 1rem;
	border: none;
	border-radius: var(--border-radius);
	cursor: pointer;
	transition: all 0.2s;

	&:--pocus {
		text-decoration: none;
	}
}

.column{

	& button{
		background-color: blue;
	}
}

.btn--primary{
	background-color: var(--color-accent);

	&:--pocus{
		background-color: var(--color-accent-dark);
		color: #fff;
	}
}

.btn--secondary{
	background-color: var(--color-primary);

	&:--pocus{
		background-color: color(var(--color-primary) alpha(80%));
		color: #fff;
	}
}

.layout-button {
	margin-top: 20px;
	margin-bottom: 20px;
	margin-right: 5px;
}

@media only screen and (max-width: 500px) {
	.layout-button {
		max-width: 100%;
		min-width: 100%;
		text-align: center;
		margin-top: 15px;
		margin-bottom: 10px;
		margin-right:0;
	}
}