.alert-popup{
  position: absolute;
  z-index: 100;
  display: none;
  width: 100%;
  height: 100vh;
  background-color: rgba(0,0,0,0.3);

  & img{
    display: block;
    max-width: 700px;
    max-height: 700px;
  }
}

.alert-popup.opened{
  display: flex;
  justify-content: center;
  align-items: center;
}

.alert-popup__container{
  position: relative;
}

.popup-close{
  position: absolute;
  top:-10px; right: -10px;
  padding: 10px;
  color: #fff;
  background-color: #000;
  border-radius: 50%;
}