.form {
  padding: 0;
}

.checkbox-choice {
  display: block;

  &>* {
    display: inline-block;
    vertical-align: middle;
  }

  & input {
    z-index: -1;
    opacity: 0;
    margin-bottom: 0;
  }

  & .checkbox {
    background-color: #fff;
    height: 20px;
    width: 20px;
    border-radius: 5px;
  }
}

.field__choice{
  display:inline-block;
  position: relative;
  margin-right: 15px;
  margin-bottom: 25px;
  padding-top: 1px;
  padding-left: 35px;
  cursor: pointer;
}

.field__choice--checkbox{
  width: 100%;
}

.field__choice input{
  position: absolute;
  z-index:-1;
  opacity:0;
}

.field__choice-indicator{
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border:1px solid var(--ltgray);
}

.field__choice--checkbox .field__choice-indicator{
  border-radius:5px;
}

.field__choice--radio .field__choice-indicator{
  border-radius: 50%;
}

.field__choice:hover input ~ .field__choice-indicator,
.field__choice input:focus ~ .field__choice-indicator{
  background-color: #eee;
}

.field__choice input:checked ~ .field__choice-indicator{
  background-color: var(--color-primary);
}

.field__choice:hover input:not([disabled]):checked ~ .field__choice-indicator,
.field__choice input:checked:focus ~ .field__choice-indicator{
  background-color: var(--color-primary);
}

.field__choice input:disabled ~ .field__choice-indicator{
  background-color: #ddd;
  opacity:0.6;
  pointer-events:none;
}

.field__choice-indicator:after{
  content:'';
  position: absolute;
  display: none;
}

.field__choice input:checked ~ .field__choice-indicator{
  border-color:#fff;
}

.field__choice input:checked ~ .field__choice-indicator:after{
  display: block;
}

.field__choice--checkbox .field__choice-indicator:after{
  top:3px; left: 7px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.field__choice--radio input:checked{
  border-color:#fff;
}

.field__choice--radio .field__choice-indicator:after{
  top:6px; left:6px;
  width: 6px;
  height: 6px;
  background-color: #fff;
  border-radius: 50%;
}

.field__choice input:disabled ~ .field__choice-indicator:after{
  background-color: #fff;
}

.field__select {
  position: relative;
  display: inline-block;
  margin-bottom: 15px;
  width: 100%;
}

.field__select select {
  display: inline-block;
  width: 100%;
  cursor: pointer;
  padding: 8px;
  outline: 0;
  border: 0;
  border-radius: 5px;
  background: #fff;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.field__select select::-ms-expand {
  display: none;
}
.field__select select:hover,
.field__select select:focus {
  color: #000;
  background: #ccc;
}
.field__select select:disabled {
  opacity: 0.5;
  pointer-events: none;
}
.select__arrow {
  position: absolute;
  top: 13px;
  right: 15px;
  width: 0;
  height: 0;
  pointer-events: none;
  border-style: solid;
  border-width: 8px 5px 0 5px;
  border-color: #7b7b7b transparent transparent transparent;
}
.field__select select:hover ~ .select__arrow,
.field__select select:focus ~ .select__arrow {
  border-top-color: #000;
}
.field__select select:disabled ~ .select__arrow {
  border-top-color: #ccc;
}

.field > label, 
.field .help-block{
  display: inline-block;
}

.field .help-block{
  padding-left:10px;
  font-size: 12px;
  font-style: italic;
}

.field.has-error input{
  border:1px solid #e74c3c;
}

.field .error-block{
  color:#e74c3c;
}

.login-form{
  margin-top: 48px;
  padding:20px;
  background-color: var(--ltgray);
}

input[type="image"]{
  width:auto;
  margin-bottom: 0;
  padding: 0;
  border:none;
}

@media only screen and (min-width: 450px) {
	.field-group > .field,
  .field-group > label {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  /*.field-group > .field:only-child{
    width: 100%;
  }*/

  .field-group .field--radio{
    width:auto;
  }

}

.login-form--small {
  max-width: 400px;
}