.pagination {
  margin-top: 20px;
  color: var(--color-primary);
}

.pagination__list {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  list-style: none;
}

.pagination__item + .pagination__item {
  margin-left: 15px;
}

.pagination__item {

  & a {
    color: inherit;

    &:--pocus {
      text-decoration: none;
    }
  }
}

.pagination__symbol, .pagination__text {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  text-transform: uppercase;
  border: 2px solid transparent;

  &:--pocus {
    border-color: currentColor;
  }

  &.is-active {
    color: #FFF;
    border-color: var(--color-secondary);
    background-color: var(--color-secondary);
  }
}

.pagination__symbol {
  width: 24px;
  height: 24px;
  border-radius: 999px;
}

.pagination__text {
  min-height: 24px;
  border-radius: var(--border-radius);
}

@media only screen and (min-width: 768px) {
  .pagination {
    margin-top: 40px;
  }

  .pagination__list {
    justify-content: flex-start;
  }
}

.pagination {
  padding-left: 100px;
  padding-right: 100px;
}

.pagination a {
  color: #8ca33c;
  font-weight: bold;
}

.pagination .pag-prev {
  margin-left: 0;
}

.pagination .pag-next {
  margin-left: 90%;
  white-space: nowrap;
}

@media only screen and (max-width: 768px) {
  .pagination .next {
    margin-left: 72%;
  }
}
