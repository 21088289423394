.featuresBlocks{
  padding:40px 0;
}

.featuresBlocks__header{
  text-align:center;
  margin-bottom:40px;
  & p{
    padding-top:20px;
  }
}

@media only screen and (min-width: 768px) { 
  .featuresBlock__wrap{
      display:flex;
      justify-content:center;
      flex-wrap: wrap;
      gap: 32px;
  }
}

.feature{
  position: relative;
  margin-bottom: 50px;
}
  
@media only screen and (min-width: 768px) { 
  .feature{
    flex: none;
    width: calc(50% - 32px);
    margin-bottom: 50px;
  }
}

@media only screen and (min-width: 926px) { 
  .feature{
    width: calc(25% - 32px);
    margin-bottom: 0;
  }
}
  
.feature__img{
  height:247px;
  margin: 0;
  border-top-left-radius: 35px;
  overflow: hidden;

  & img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.feature h3 a::after{
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  pointer-events: auto;
}

.feature__content{
  padding:20px;
  text-align:center;
  & h3{
    margin-bottom:20px;
    & a{
      font-size:20px;
      font-weight:700;
      line-height:1;
      color:#546122;
      text-decoration:none;
    }
  }
}

.border--blue{
  border-bottom:5px solid #87B2D8;
}

.border--green{
  border-bottom:5px solid #8CA33C;
}

.border--orange{
  border-bottom:5px solid #EEB21E;
}

.border--purple{
  border-bottom:5px solid #7A426B;
}
