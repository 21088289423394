.map {
  /*height: 300px;*/
  margin-bottom: 20px;

  & iframe {
    width: 100%;
    height: 100%;
  }
}

@media only screen and (min-width:768px) {
  .map {
    height: 400px;
  }
}
