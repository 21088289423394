@custom-selector :--hocus :hover, :focus;
@custom-selector :--pocus :hover, :focus, :active;

/* assistant-regular - latin */
@font-face {
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/assistant-v3-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Assistant'), local('Assistant-Regular'),
       url('../fonts/assistant-v3-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/assistant-v3-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/assistant-v3-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/assistant-v3-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/assistant-v3-latin-regular.svg#Assistant') format('svg'); /* Legacy iOS */
}
/* assistant-600 - latin */
@font-face {
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/assistant-v3-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Assistant SemiBold'), local('Assistant-SemiBold'),
       url('../fonts/assistant-v3-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/assistant-v3-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/assistant-v3-latin-600.woff') format('woff'), /* Modern Browsers */
       url('../fonts/assistant-v3-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/assistant-v3-latin-600.svg#Assistant') format('svg'); /* Legacy iOS */
}
/* assistant-700 - latin */
@font-face {
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/assistant-v3-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Assistant Bold'), local('Assistant-Bold'),
       url('../fonts/assistant-v3-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/assistant-v3-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/assistant-v3-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/assistant-v3-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/assistant-v3-latin-700.svg#Assistant') format('svg'); /* Legacy iOS */
}

html {
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  font-kerning: auto;
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 16px;
  font-size: 16px;
  letter-spacing: 0.02em;
  line-height: 1.5;
}

/* @media only screen and (min-width:768px) {
  html {
    font-size: 16px;
  }
}*/

*, *:before, *:after {
  box-sizing: inherit;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

*:focus{
  outline: none
}

body{
  position: relative;
  min-height:100%;
  display:flex;
  flex-direction:column;
  margin: 0;
  font-family: var(--font-primary);
}

a[href]:empty::before {
  content: attr(href);
}

main{
  flex:1 0 auto;
}

img, svg{
  max-width: 100%;
  height:auto;
  vertical-align:middle;
}

svg {
  display: block;
  height: 100%;

  & path {
    transition: 0.2s;
  }
}

button{
  border:0;
  -webkit-appearance:none;
}

/* removes yellow bg in webkit browsers */
input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0px 9999px white;
}
