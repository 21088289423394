.leadership {
	width: 100%;

	& h3 {
		color: #546122;
		text-align: center;
	}

	& section img {
		margin-bottom: 20px;
		width: 100%;
		max-height: 300px;
	}
}

.leadership__list {
	list-style: none;
	padding-left: 0;
	padding-bottom: 50px;

	& li {
		display: block;
		margin-top: 20px;
		text-align: center;

		& strong{
			display: block;
		}

		& p {
			margin-bottom: 0;
		}
	}
}

.gray-underline {
	color: whitesmoke;
	height: 1px;
	width: 100%;
	margin: 0 0 60px 0;
}

@media only screen and (min-width: 768px) {

	.leadership {
		& h3 {
			text-align: left;
		}
	}

	.leadership__list{
		display: flex;
		flex-wrap: wrap;

		& li{
			width: 50%;
			max-width: 340px;
			text-align: left;
		}
	}

	.document-wrap {
		flex-direction: column;
		align-items: center;
		align-self: center;
		align-content: center;
		max-width: 340px;
		margin-left: auto;
		margin-right: auto;
		
	}
}

.board-docs {
	text-align: left;
	color: #8CA33C;
	font-weight: bold;
}


.document-wrap {
	text-align: left;
	list-style: none;
	padding-left: 0;
	& h4 {
		margin-bottom: 20px;
	}
	& .entry {
		text-align: left;
	}
}

.staff-block {
	margin-bottom: 30px;
}

.dept-block {
	margin-bottom: 20px;
}

.column-reverse {
	display: flex;
	flex-direction: column-reverse;
}

.staff-block ul li {
	margin-bottom: 8px;
}

.staff-block h3 {
	margin-bottom: 10px;
}